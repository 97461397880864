import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { Account } from 'types/Account';

export function LocationsMultiselect({
    locations,
    value,
    onChange,
    readOnly,
}: {
    locations: Account[];
    value: string[];
    onChange: (v: string[]) => void;
    readOnly?: boolean;
}) {
    const [open, setOpen] = React.useState(false);
    return (
        <Autocomplete
            style={{ width: '100%' }}
            options={locations}
            multiple
            disabled={readOnly}
            value={locations.filter((c) => value.includes(c.id.toString()))}
            onChange={(_, v: Account[]) => onChange(v.map((c: Account) => c.id.toString()))}
            getOptionLabel={(c: Account) => c.customerName}
            ChipProps={{ size: 'small', style: { marginTop: 4, marginBottom: 4 } }}
            renderInput={(params) => <TextField {...params} label="Groups" />}
            fullWidth
            open={open}
            onOpen={() => setOpen(true)}
            onClose={(_, action) => {
                if (action === 'selectOption' || action === 'removeOption') return;
                setOpen(false);
            }}
        />
    );
}
