import config from 'global-variables';
import { useMutation, useQueryClient } from 'react-query';
import { getToken } from './useLogin';

async function deleteOrderLine({ orderId, orderLineId }: { orderId: number; orderLineId: number }): Promise<boolean> {
    const token = getToken();
    if (!token) return false;
    const host = config.restAPIUrl;
    const res = await fetch(`${host}/orders/provider/${orderId}/${orderLineId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });
    if (!res.ok) {
        throw new Error('Failed to remove order item');
    }
    await res.text();
    return true;
}

export function useDeleteOrderLine() {
    const queryClient = useQueryClient();
    return useMutation(deleteOrderLine, {
        onSuccess: (_, { orderId }) => {
            queryClient.invalidateQueries(['provider-order-details', orderId.toString()]);
        },
    });
}
