import config from 'global-variables';
import { useMutation, useQueryClient } from 'react-query';
import { getToken } from './useLogin';

async function updateOrderPO({ orderId, poNumber }: { orderId: number; poNumber: string }): Promise<boolean> {
    const token = getToken();
    if (!token) return false;
    const host = config.restAPIUrl;
    const res = await fetch(`${host}/orders/set-po-number/${orderId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ poNumber }),
    });
    if (!res.ok) {
        const error = await res.text();
        throw new Error(error);
    }
    await res.text();
    return true;
}

export function useUpdateOrderPO() {
    const queryClient = useQueryClient();
    return useMutation(updateOrderPO, {
        onSuccess: (_, { orderId }) => {
            queryClient.invalidateQueries(['invoice-data', orderId.toString()]);
            queryClient.invalidateQueries(['open-orders']);
        },
    });
}
