import { Button, Grid2, GridSize, Link, Paper, TextField, useMediaQuery, useTheme } from '@mui/material';
import { useGetOnHandParts } from 'hooks/useGetOnHandParts';
import { useGetFleetUserAccounts } from 'hooks/useGetFleetUserAccounts';
import React, { useEffect } from 'react';
import { Account } from 'types/Account';
import { PartsList } from './PartsList';
import { TitleText } from 'components/Title/Title';
import { AccountSelector } from 'components/AccountSelector/AccountSelector';
import { useCreateOrder } from 'hooks/useCreateOrder';
import { OrderRequest } from 'types/Orders';
import { Controller, useForm } from 'react-hook-form';
import { confirmDialog } from 'components/Dialogs/ConfirmDialog';

function AccountDetails({
    account,
    breakpoint,
    position,
    onChangeAccount,
}: {
    account: Account;
    breakpoint: 'lg' | 'xs' | 'md' | 'sm';
    position: 'title' | 'notes';
    onChangeAccount: () => void;
}) {
    const size: { [key: string]: GridSize } = { xs: 12 };
    size[breakpoint] = 6;
    const textAlign: { [key: string]: 'left' | 'right' } = { xs: 'left' };
    textAlign[breakpoint] = 'right';
    const theme = useTheme();
    const tabletView = useMediaQuery(theme.breakpoints.down(breakpoint));
    if (tabletView && position === 'notes') return null;
    if (!tabletView && position === 'title') return null;

    return (
        <>
            <Grid2 size={size}>
                <TitleText>Current Location</TitleText>
            </Grid2>
            <Grid2 size={size} textAlign={textAlign}>
                <div style={{ fontSize: 14 }}>
                    <b>{account.customerName}</b>
                    <br />
                    {account.address}
                    <br />
                    {account.city}, {account.state} {account.zip}
                    <br />
                    <Link style={{ cursor: 'pointer' }} onClick={onChangeAccount}>
                        [choose another location]
                    </Link>
                </div>
            </Grid2>
        </>
    );
}

export function CreateOrder() {
    const accounts = useGetFleetUserAccounts();
    const [selectedAccount, setSelectedAccount] = React.useState<string>('none');
    const onHandParts = useGetOnHandParts(selectedAccount || 'none');
    const [error, setError] = React.useState<string | null>(null);
    const { control, setValue, handleSubmit, reset } = useForm<OrderRequest>({
        defaultValues: {
            newTires: [],
            retreadTires: [],
            customerNotes: '',
        },
    });
    const createOrder = useCreateOrder();

    useEffect(() => {
        if (selectedAccount === 'none') return;
        setValue('accountID', parseInt(selectedAccount, 10));
    }, [selectedAccount, setValue]);

    if (!accounts.data) return null;
    const account = accounts.data.find((acc) => acc.id.toString() === selectedAccount) || null;

    if (!account)
        return (
            <Paper style={{ margin: '80px auto', maxWidth: 600, padding: 24 }}>
                <Grid2 container spacing={2}>
                    <Grid2 size={12} style={{ margin: '0 auto' }}>
                        <TitleText>Choose your location</TitleText>
                        <AccountSelector
                            value={selectedAccount}
                            onChange={setSelectedAccount}
                            accounts={accounts.data}
                            labelField="customerName"
                        />
                    </Grid2>
                </Grid2>
            </Paper>
        );

    const submitOrder = async (order: OrderRequest) => {
        await createOrder.mutate(order, {
            onSuccess: (data) => {
                setSelectedAccount('none');
                reset();
                confirmDialog(
                    'Order Submitted',
                    <>
                        Your order confirmation number is <b>{data.id}</b>.
                        <br />
                        Your MCSN Provider Order # and Delivery Receipt # will be available once submitted by your MCSN
                        provider for this location.
                        <br />
                        <br />
                        <b style={{ color: 'red' }}>Please create your cart in SAP now</b>
                        <br />
                        <br />
                        <b>Thank you for your business.</b>
                    </>,
                    true,
                );
            },
            onError: (err: any) => {
                setError(err.message);
            },
        });
    };

    return (
        <Grid2 container spacing={4} style={{ padding: '0 24px' }}>
            <AccountDetails
                account={account}
                breakpoint="lg"
                position="title"
                onChangeAccount={() => setSelectedAccount('none')}
            />
            <Grid2 size={{ lg: 8, xs: 12 }}>
                <PartsList
                    parts={onHandParts.data?.newParts}
                    label="New Tires"
                    control={control}
                    setValue={setValue}
                    type="newTires"
                />
                <PartsList
                    parts={onHandParts.data?.retreadParts}
                    label="Retread Tires"
                    control={control}
                    setValue={setValue}
                    type="retreadTires"
                />
            </Grid2>
            <Grid2 size={{ lg: 4, xs: 12 }}>
                <Grid2 container spacing={2}>
                    <AccountDetails
                        account={account}
                        breakpoint="lg"
                        position="notes"
                        onChangeAccount={() => setSelectedAccount('none')}
                    />
                    <Grid2 size={12}>
                        <Controller
                            name="customerNotes"
                            control={control}
                            rules={{ required: 'Please fill out the customer notes field' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    minRows={10}
                                    multiline
                                    fullWidth
                                    error={!field.value && error !== null}
                                    label="Customer Notes"
                                    slotProps={{
                                        inputLabel: { shrink: true },
                                    }}
                                />
                            )}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        {error && (
                            <div style={{ color: 'red', textAlign: 'right', marginBottom: 16, marginRight: 2 }}>
                                {error}
                            </div>
                        )}
                        <div style={{ textAlign: 'right', width: '100%' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setError(null);
                                    handleSubmit(submitOrder, (err) => {
                                        const errors = [];
                                        for (const e of Object.values(err)) {
                                            if (!e.message) continue;
                                            errors.push(e.message);
                                        }
                                        setError(errors.join('; '));
                                    })();
                                }}
                            >
                                Submit Order
                            </Button>
                        </div>
                    </Grid2>
                </Grid2>
            </Grid2>
        </Grid2>
    );
}
