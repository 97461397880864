import config from 'global-variables';
import { useMutation } from 'react-query';
import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import { TokenData } from '../types/TokenData';

export function getToken() {
    return localStorage.getItem('token');
}

function getTokenData(): { token?: string; userData?: TokenData; issuedAt?: number } {
    // decode jwt token and return user data
    const jwtData = getToken();
    if (!jwtData) return {};

    const userData = jwtData.split('.')[1];
    if (!userData) return {};

    const parsedData = JSON.parse(atob(userData));

    if (parsedData.exp < Date.now() / 1000) {
        localStorage.removeItem('token');
        return {};
    }

    return {
        token: localStorage.getItem('token')!,
        userData: {
            ...parsedData,
            exp: undefined,
            iat: undefined,
        },
        issuedAt: parsedData.iat,
    };
}

const userData = signal<TokenData | undefined>(getTokenData().userData);

async function doLogin({
    username,
    password,
    mode,
}: {
    username: string;
    password: string;
    mode: 'fleet' | 'provider' | 'none';
}) {
    if (mode === 'none') {
        throw new Error('Please select an access mode to continue.');
    }

    const host = config.restAPIUrl;
    const res = await fetch(`${host}/${mode}-login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
    });
    if (!res.ok) {
        const test = await res.text();
        throw new Error('Login failed: ' + test);
    }
    const data = await res.json();
    return data;
}

async function refreshToken({ token }: { token: string }) {
    const host = config.restAPIUrl;
    const res = await fetch(`${host}/login/refresh-token`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });
    if (!res.ok) {
        const test = await res.text();
        throw new Error('Refresh token failed: ' + test);
    }
    const data = await res.json();
    return data;
}

export function useLogin() {
    return useMutation(doLogin, {
        onSuccess: (data) => {
            localStorage.setItem('token', data.token);
            userData.value = getTokenData().userData;
        },
    });
}

export function logout() {
    localStorage.removeItem('token');
    userData.value = undefined;
}

const refreshInterval = 30 * 60; // 30 minutes (in seconds)

function checkRefresh() {
    const { token, issuedAt } = getTokenData();
    if (!token || !issuedAt) return;
    const tokenAge = Date.now() / 1000 - issuedAt;
    if (tokenAge < refreshInterval) return;
    refreshToken({ token })
        .then((data) => {
            localStorage.setItem('token', data.token);
            // update user data only if it has changed
            const newUserData = getTokenData().userData;
            const oldUserData = userData.value;
            if (JSON.stringify(newUserData) !== JSON.stringify(oldUserData)) {
                userData.value = newUserData;
            }
        })
        .catch(() => {
            console.log('Failed to refresh token');
        });
}

setInterval(checkRefresh, refreshInterval * 1000);
setTimeout(checkRefresh, 1000);

export function useUserData() {
    useSignals();
    return userData.value;
}
