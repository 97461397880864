import config from 'global-variables';
import { useQuery } from 'react-query';
import { getToken } from './useLogin';
import { MCSNInventory } from 'types/MCSNInventory';

async function getInventory({ mcsnLocationId }: { mcsnLocationId?: string }): Promise<MCSNInventory[]> {
    const token = getToken();
    if (!token || mcsnLocationId === 'none') return [];
    const host = config.restAPIUrl;
    const res = await fetch(`${host}/provider-users/inventory/${mcsnLocationId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });
    const data = await res.json();
    return data as MCSNInventory[];
}

export function useGetInventory(mcsnLocationId: string) {
    return useQuery(['inventory', mcsnLocationId], () => getInventory({ mcsnLocationId }), {
        refetchInterval: 0,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });
}
