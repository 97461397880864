import config from 'global-variables';
import { useQuery } from 'react-query';
import { getToken } from './useLogin';

async function getODFLLocations({ mcsnLocationId }: { mcsnLocationId: string }) {
    const token = getToken();
    if (!token || !mcsnLocationId || mcsnLocationId === 'none') return [];
    const host = config.restAPIUrl;
    const res = await fetch(`${host}/provider-users/odfl-locations/${mcsnLocationId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });
    const data = await res.json();
    return data as { id: number; customerName: string }[];
}

export function useGetODFLLocations(mcsnLocationId: string) {
    return useQuery(['odfl-locations', mcsnLocationId], () => getODFLLocations({ mcsnLocationId }), {
        refetchInterval: 0,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });
}
