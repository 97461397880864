import config from 'global-variables';
import { useMutation, useQueryClient } from 'react-query';
import { getToken } from './useLogin';
import { OrderLineEditFields } from 'types/Orders';

async function updateOrderLine({
    orderId,
    orderLineId,
    newValues,
}: {
    orderId: number;
    orderLineId: number;
    newValues: OrderLineEditFields;
}): Promise<boolean> {
    const token = getToken();
    if (!token) return false;
    const host = config.restAPIUrl;
    const res = await fetch(`${host}/orders/provider/${orderId}/${orderLineId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newValues),
    });
    if (!res.ok) {
        throw new Error('Failed to update order line');
    }
    await res.text();
    return true;
}

export function useUpdateOrderLine() {
    const queryClient = useQueryClient();
    return useMutation(updateOrderLine, {
        onSuccess: (_, { orderId }) => {
            queryClient.invalidateQueries(['provider-order-details', orderId.toString()]);
        },
    });
}
