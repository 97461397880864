import config from 'global-variables';
import { useMutation, useQueryClient } from 'react-query';
import { getToken } from './useLogin';
import { OrderHeaderEditFields } from 'types/Orders';

async function updateProviderOrderDetails({
    orderId,
    newValues,
}: {
    orderId: number;
    newValues: OrderHeaderEditFields;
}): Promise<boolean> {
    const token = getToken();
    if (!token) return false;
    const host = config.restAPIUrl;
    const res = await fetch(`${host}/orders/provider/detail/${orderId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newValues),
    });
    if (!res.ok) {
        throw new Error('Failed to update order details');
    }
    await res.text();
    return true;
}

export function useUpdateProviderOrderDetails() {
    const queryClient = useQueryClient();
    return useMutation(updateProviderOrderDetails, {
        onSuccess: (_, { orderId }) => {
            queryClient.invalidateQueries(['provider-order-details', orderId.toString()]);
            queryClient.invalidateQueries('provider-orders');
        },
    });
}
