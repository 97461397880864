import { Header } from 'components/Header/Header';
import { useUserData } from 'hooks/useLogin';
import { InventoryManagement } from 'modules/Inventory/InventoryManagement';
import { Login } from 'modules/Login/Login';
import { CreateOrder } from 'modules/Orders/CreateOrder';
import { OpenOrders } from 'modules/Orders/OpenOrders';
import { OrdersHistory } from 'modules/Orders/OrdersHistory';
import { PendingOrders } from 'modules/Orders/PendingOrders';
import { ProviderOrders } from 'modules/ProviderOrders/ProviderOrders';
import { UserList } from 'modules/Users/UserList';
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

function onlyFleets(mode: string, Component: React.ComponentType) {
    if (mode === 'fleet') {
        return Component;
    }
    return () => <Navigate to="/my-orders" />;
}

function onlyProviders(mode: string, Component: React.ComponentType) {
    if (mode === 'provider') {
        return Component;
    }
    return () => <Navigate to="/orders/create" />;
}

function addHeader(Component: React.ComponentType, filterAdmin: boolean = false) {
    const ComponentWithHeader = () => {
        const userData = useUserData();
        if (filterAdmin && !userData?.isAdmin) {
            return <Navigate to="/orders/create" />;
        }
        return (
            <>
                <Header />
                <Component />
            </>
        );
    };
    return ComponentWithHeader;
}

export function Router() {
    const userData = useUserData();
    if (!userData) {
        return (
            <>
                <Header />
                <Login />
            </>
        );
    }
    const { mode } = userData;
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/backoffice" element={<Navigate to="/" />} />
                <Route path="/" element={<Navigate to={mode === 'fleet' ? '/orders/create' : '/my-orders'} />} />
                <Route path="/orders/create" Component={onlyFleets(mode, addHeader(CreateOrder))} />
                <Route path="/orders/pending" Component={onlyFleets(mode, addHeader(PendingOrders))} />
                <Route path="/orders/awaiting" Component={onlyFleets(mode, addHeader(OpenOrders))} />
                <Route path="/orders/history" Component={onlyFleets(mode, addHeader(OrdersHistory))} />
                <Route path="/admin/users" Component={onlyFleets(mode, addHeader(UserList, true))} />
                <Route path="/my-orders" Component={onlyProviders(mode, addHeader(ProviderOrders))} />
                <Route path="/inventory" Component={onlyProviders(mode, addHeader(InventoryManagement))} />
            </Routes>
        </BrowserRouter>
    );
}
