import { SelectProps, Select as MUISelect, InputLabel, FormControl } from '@mui/material';
import React from 'react';

export function Select<Value = unknown>(props: SelectProps<Value>) {
    return (
        <FormControl fullWidth>
            {props.label && <InputLabel id="odfl-select-label">{props.label}</InputLabel>}
            <MUISelect labelId="odfl-select-label" {...props}>
                {props.children}
            </MUISelect>
        </FormControl>
    );
}
