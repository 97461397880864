import React, { useEffect } from 'react';
import { TableContainer, TextField, Table, TableBody, TableRow, TableCell, Paper, styled } from '@mui/material';
import { PartWithInventory } from 'types/Parts';
import { TitleText } from 'components/Title/Title';
import { Control, Controller, ControllerRenderProps, UseFormSetValue } from 'react-hook-form';
import { OrderRequest } from 'types/Orders';

function QTYInput({
    position,
    max,
    field,
}: {
    position?: 'Left' | 'Right';
    max: number;
    field: ControllerRenderProps<OrderRequest, `newTires.${number}.qty` | `retreadTires.${number}.qty`>;
}) {
    return (
        <TextField
            {...field}
            value={field.value || ''}
            onChange={(e) => field.onChange(e.target.value)}
            label={`${position || ''} QTY`}
            style={{ marginRight: position === 'Left' ? 8 : 0, width: 90 }}
            variant="outlined"
            size="small"
            type="number"
            slotProps={{
                inputLabel: { shrink: true },
                htmlInput: { min: 0, max, step: 1 },
            }}
        />
    );
}

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#FAFAFA',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function setNoTireImage(e: React.SyntheticEvent<HTMLImageElement, Event>) {
    e.currentTarget.src = '/images/tires/notireimage.jpg';
    e.currentTarget.style.filter = 'grayscale(100%)';
    e.currentTarget.style.opacity = '0.2';
}

export function PartsList({
    parts,
    label,
    control,
    setValue,
    type,
}: {
    parts?: PartWithInventory[];
    label: string;
    control: Control<OrderRequest, any>;
    setValue: UseFormSetValue<OrderRequest>;
    type: 'newTires' | 'retreadTires';
}) {
    useEffect(() => {
        if (!parts) {
            setValue(type, []);
            return;
        }
        const list: OrderRequest['newTires'] = [];
        parts.forEach((part) => {
            if (part.part.directional === 'Y') {
                list.push({
                    qtyType: 'LEFT',
                    inventoryId: part.inventory.id,
                });
                list.push({
                    qtyType: 'RIGHT',
                    inventoryId: part.inventory.id,
                });
            } else {
                list.push({
                    qtyType: null,
                    inventoryId: part.inventory.id,
                });
                // @ts-expect-error
                list.push(null);
            }
        });
        setValue(type, list);
    }, [parts, setValue, type]);

    if (!parts || parts.length === 0) return null;

    return (
        <>
            <TitleText>{label}</TitleText>
            <TableContainer component={Paper} style={{ marginBottom: 24 }}>
                <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
                    <TableBody>
                        {parts.map((part, i) => (
                            <StyledTableRow key={part.part.id}>
                                <TableCell component="th" scope="row" width={125} align="center">
                                    <img
                                        src={`/images/tires/T${part.part.vendor}${part.part.type}.jpg`}
                                        alt="tire photo"
                                        style={{ mixBlendMode: 'multiply', width: 90 }}
                                        // @ts-ignore
                                        onError={setNoTireImage}
                                    />
                                    <img
                                        src={`/images/vendors/${part.part.img}`}
                                        alt="vendor logo"
                                        style={{ margin: '0 auto', height: 25, marginTop: -4 }}
                                    />
                                </TableCell>
                                <TableCell style={{ minWidth: 150 }}>
                                    <b>{part.part.name}</b>
                                    <br />
                                    {part.part.custPN} - {part.inventory.mcsnPartNum}
                                </TableCell>
                                <TableCell width={200}>
                                    {part.part.formSize}
                                    <br />
                                    {part.part.tireInfo}
                                </TableCell>
                                <TableCell width={250}>{part.part.group2}</TableCell>
                                <TableCell width={100} align="right" style={{ whiteSpace: 'nowrap' }}>
                                    On Hand
                                    <br />
                                    <b>{part.inventory.mcsnInvLevel}</b>
                                </TableCell>
                                <TableCell width={190}>
                                    {part.part.directional === 'Y' ? (
                                        <div style={{ whiteSpace: 'nowrap' }}>
                                            <Controller
                                                name={`${type}.${i * 2}.qty`}
                                                control={control}
                                                render={({ field }) => (
                                                    <QTYInput
                                                        position="Left"
                                                        max={part.inventory.mcsnInvLevel}
                                                        field={field}
                                                    />
                                                )}
                                            />
                                            <Controller
                                                name={`${type}.${i * 2 + 1}.qty`}
                                                control={control}
                                                render={({ field }) => (
                                                    <QTYInput
                                                        position="Right"
                                                        max={part.inventory.mcsnInvLevel}
                                                        field={field}
                                                    />
                                                )}
                                            />
                                        </div>
                                    ) : (
                                        <Controller
                                            name={`${type}.${i * 2}.qty`}
                                            control={control}
                                            render={({ field }) => (
                                                <QTYInput max={part.inventory.mcsnInvLevel} field={field} />
                                            )}
                                        />
                                    )}
                                </TableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
