import { Autorenew } from '@mui/icons-material';
import { Button, Icon, IconButton, InputAdornment, TextField } from '@mui/material';
import { showSnackbar } from 'components/Snackbar/SnackbarProvider';
import React, { useEffect, useRef, useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { User } from 'types/User';

function isSecure(password: string) {
    if (password.length < 8) return false;
    if (!/[a-z]/.test(password)) return false;
    if (!/[A-Z]/.test(password)) return false;
    if (!/[0-9]/.test(password)) return false;
    if (!/[@$!.]/.test(password)) return false;
    return true;
}

export function generatePassword() {
    const length = 12;
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@$!.@$!.@$!.1234567890';
    let retVal = '';
    while (!isSecure(retVal)) {
        retVal = '';
        for (let i = 0, n = charset.length; i < length; i += 1) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
    }
    return retVal;
}

export function PasswordInput({
    field,
    value,
    startDisabled,
    setValue,
}: {
    field?: ControllerRenderProps<User, 'password'>;
    value?: string;
    startDisabled?: boolean;
    setValue: (val: string) => void;
}) {
    const [password, setPassword] = useState(value);
    const [initialized, setInitialized] = useState(!!value);
    const [updatePassword, setUpdatePassword] = useState(!startDisabled);
    const [copied, setCopied] = useState(false);
    const timerRef = useRef<number | null>(null);

    useEffect(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
        if (copied) {
            timerRef.current = window.setTimeout(() => {
                setCopied(false);
            }, 3000);
        }
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = null;
            }
        };
    }, [copied]);

    useEffect(() => {
        if (!initialized) {
            const pass = generatePassword();
            if (updatePassword) {
                setTimeout(() => setValue(pass), 10);
            }
            setPassword(pass);
            setInitialized(true);
        } else if (value) {
            setPassword(value);
        }
    }, [value, setValue, initialized, setInitialized, setPassword, updatePassword]);

    return (
        <TextField
            size="small"
            disabled={!updatePassword}
            type={!updatePassword ? 'password' : 'text'}
            slotProps={{
                input: {
                    // readOnly: true, // this is to allow admins to manually set any password
                    endAdornment: (
                        <InputAdornment position="end">
                            {!updatePassword ? (
                                <Button
                                    size="small"
                                    color="info"
                                    onClick={() => {
                                        const x = generatePassword();
                                        setValue(x);
                                        setPassword(x);
                                        setUpdatePassword(true);
                                    }}
                                >
                                    update password
                                </Button>
                            ) : (
                                <IconButton
                                    onClick={() => {
                                        const x = generatePassword();
                                        if (updatePassword) setValue(x);
                                        setPassword(x);
                                    }}
                                >
                                    <Autorenew style={{ color: 'gray' }} />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                    startAdornment: !updatePassword ? undefined : (
                        <InputAdornment position="start">
                            <IconButton
                                onClick={async () => {
                                    await navigator.clipboard.writeText(password || '');
                                    showSnackbar({ message: 'Password copied to clipboard', severity: 'success' });
                                }}
                            >
                                <Icon>content_copy</Icon>
                            </IconButton>
                        </InputAdornment>
                    ),
                },
            }}
            {...field}
            value={password || ''}
            label="Password"
            helperText={copied ? <span style={{ color: 'green' }}>Password copied to clipboard</span> : undefined}
        />
    );
}
