import config from 'global-variables';
import { useMutation, useQueryClient } from 'react-query';
import { getToken } from './useLogin';

async function disableUser({ userId }: { userId: string }) {
    const token = getToken();
    if (!token || !userId) return null;
    const host = config.restAPIUrl;
    const res = await fetch(`${host}/admin/users/${userId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });
    const data = await res.json();
    return data;
}

export function useDisableUser() {
    const queryClient = useQueryClient();
    return useMutation((userId: string) => disableUser({ userId }), {
        onSuccess: (_, userId) => {
            queryClient.invalidateQueries(['user-data', userId]);
            queryClient.invalidateQueries(['users-list']);
        },
    });
}
