import config from 'global-variables';
import { useQuery } from 'react-query';
import { getToken } from './useLogin';
import { PartWithInventory } from 'types/Parts';

interface OnHandTires {
    newParts: PartWithInventory[];
    retreadParts: PartWithInventory[];
}

async function getOnHandParts({ accountId }: { accountId?: string }): Promise<OnHandTires> {
    const token = getToken();
    if (!token || !accountId || accountId === 'none') return { newParts: [], retreadParts: [] };
    const host = config.restAPIUrl;
    const res = await fetch(`${host}/orders/onhand-tires/${accountId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });
    const data = await res.json();
    return data as OnHandTires;
}

export function useGetOnHandParts(accountId: string) {
    return useQuery(['onhand-tires', accountId], () => getOnHandParts({ accountId }), {
        refetchInterval: 0,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });
}
