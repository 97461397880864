import config from 'global-variables';
import { useQuery } from 'react-query';
import { getToken } from './useLogin';
import { Invoice } from 'types/Invoices';

async function getInvoice({ invoiceID }: { invoiceID?: string }): Promise<Invoice | null> {
    const token = getToken();
    if (!token || !invoiceID) return null;
    const host = config.restAPIUrl;
    const res = await fetch(`${host}/invoices/invoice/${invoiceID}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });
    const data = await res.json();
    return data as Invoice;
}

export function useGetInvoice(invoiceID?: string) {
    return useQuery(['invoice-data', invoiceID], () => getInvoice({ invoiceID }), {
        refetchInterval: 0,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });
}
