import {
    Button,
    Grid2,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import React, { useEffect } from 'react';
import { AccountSelector } from 'components/AccountSelector/AccountSelector';
import { Controller, useForm } from 'react-hook-form';
import { useGetProviderUserAccounts } from 'hooks/useGetProviderUserAccounts';
import { useGetInventory } from 'hooks/useGetInventory';
import { NoResultsRow } from 'components/NoResultsRow/NoResultsRow';
import { useUpdateInventory } from 'hooks/useUpdateInventory';
import { NewInventoryItem } from 'types/MCSNInventory';
import { showSnackbar } from 'components/Snackbar/SnackbarProvider';

const HeaderCell = styled(TableCell)(() => ({
    fontWeight: 'bold',
    backgroundColor: '#F5F5F5',
}));

export function InventoryManagement() {
    const accounts = useGetProviderUserAccounts();
    const [selectedAccount, setSelectedAccount] = React.useState<string>('none');
    const [updating, setUpdating] = React.useState(false);
    const inventory = useGetInventory(selectedAccount);
    const { control, handleSubmit, reset } = useForm<NewInventoryItem[]>({
        defaultValues: [],
    });
    const inveetoryUpdate = useUpdateInventory();

    useEffect(() => {
        if (selectedAccount === 'none') {
            reset([]);
            return;
        }
        reset(
            inventory.data?.map((item) => ({
                id: item.id,
                mcsnInvLevel: item.mcsnInvLevel,
            })),
        );
    }, [inventory.data, reset, selectedAccount]);

    if (!accounts.data) return null;
    const account = accounts.data.find((acc) => acc.id.toString() === selectedAccount) || null;

    const handleUpdate = (newInventory: NewInventoryItem[]) => {
        if (!account) return;
        if (!newInventory) return;
        setUpdating(true);
        inveetoryUpdate.mutate(
            { mcsnLocationId: account.id.toString(), newInventory },
            {
                onSuccess: () => {
                    showSnackbar({
                        message: 'Inventory updated successfully',
                        severity: 'success',
                    });
                    setUpdating(false);
                },
                onError: () => {
                    showSnackbar({
                        message: 'Failed to update inventory',
                        severity: 'error',
                    });
                    setUpdating(false);
                },
            },
        );
    };

    return (
        <Grid2 container spacing={2} style={{ padding: '0 24px' }}>
            <Grid2 size={{ xs: 12, sm: 8, md: 9, lg: 10, xl: 11 }}>
                <AccountSelector
                    value={selectedAccount}
                    onChange={setSelectedAccount}
                    accounts={accounts.data}
                    labelField="mcsnDCDescr"
                />
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 4, md: 3, lg: 2, xl: 1 }}>
                <Button
                    fullWidth
                    variant="contained"
                    disabled={updating || selectedAccount === 'none'}
                    color="primary"
                    onClick={() => {
                        handleSubmit(handleUpdate)();
                    }}
                >
                    Update
                </Button>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
                <TableContainer component={Paper} style={{ marginBottom: 24 }}>
                    <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <HeaderCell>Part #</HeaderCell>
                                <HeaderCell>Description</HeaderCell>
                                <HeaderCell width={140} align="center">
                                    Inventory Level
                                </HeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(inventory.isLoading || accounts.isLoading) && (
                                <NoResultsRow colSpan={8} text="Loading..." />
                            )}
                            {!inventory.isLoading && inventory.data?.length === 0 && (
                                <NoResultsRow
                                    colSpan={8}
                                    text={
                                        account
                                            ? `No inventory found for ${account.mcsnDCDescr}`
                                            : 'Select an account to view inventory'
                                    }
                                />
                            )}
                            {(inventory.data || []).map((item, index) => (
                                <TableRow key={item.id}>
                                    <TableCell>{item.mcsnPartNum}</TableCell>
                                    <TableCell>{item.mcsnPartDesc}</TableCell>
                                    <TableCell>
                                        <Controller
                                            name={`${index}.mcsnInvLevel`}
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    size="small"
                                                    variant="outlined"
                                                    type="number"
                                                    fullWidth
                                                    value={field.value || 0}
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.value.length
                                                                ? parseInt(e.target.value, 10)
                                                                : e.target.value,
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid2>
        </Grid2>
    );
}
