import config from 'global-variables';
import { useQuery } from 'react-query';
import { getToken } from './useLogin';
import { PendingOrder } from 'types/Orders';

async function getOrdersHistory({
    accountId,
    from,
    to,
}: {
    accountId?: string;
    from?: string;
    to?: string;
}): Promise<PendingOrder[]> {
    const token = getToken();
    if (!token || !from || !to) return [];
    const host = config.restAPIUrl;
    const res = await fetch(
        `${host}/orders/history?from=${from}&to=${to}&accountID=${accountId !== 'none' ? accountId : ''}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        },
    );
    const data = await res.json();
    return data as PendingOrder[];
}

export function useGetOrdersHistory(accountId: string, from: string, to: string) {
    return useQuery(['orders-history', from, to, accountId], () => getOrdersHistory({ accountId, from, to }), {
        refetchInterval: 0,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });
}
