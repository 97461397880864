import {
    Button,
    Grid2,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import React from 'react';
import { useGetPendingOrders } from 'hooks/useGetPendingOrders';
import dayjs from 'dayjs';
import { AccountSelector } from 'components/AccountSelector/AccountSelector';
import { useGetFleetUserAccounts } from 'hooks/useGetFleetUserAccounts';
import { InvoicePopup } from 'components/InvoicePopup/InvoicePopup';
import { NoResultsRow } from 'components/NoResultsRow/NoResultsRow';

const HeaderCell = styled(TableCell)(() => ({
    fontWeight: 'bold',
    backgroundColor: '#F5F5F5',
}));

export function PendingOrders() {
    const accounts = useGetFleetUserAccounts();
    const [selectedAccount, setSelectedAccount] = React.useState<string>('none');
    const [selectedInvoice, setSelectedInvoice] = React.useState<string | undefined>(undefined);
    const orders = useGetPendingOrders(selectedAccount);

    return (
        <Grid2 container spacing={2} style={{ padding: '0 24px', marginTop: 32 }}>
            <Grid2 size={{ xs: 12, md: 4 }}>
                <AccountSelector
                    label="Filter by Location"
                    value={selectedAccount}
                    onChange={setSelectedAccount}
                    accounts={accounts.data || []}
                    noSelectionText="All Locations"
                    labelField="customerName"
                />
            </Grid2>
            <TableContainer component={Paper} style={{ marginBottom: 24 }}>
                <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <HeaderCell>Location</HeaderCell>
                            <HeaderCell>Work Order #</HeaderCell>
                            <HeaderCell>Account #</HeaderCell>
                            <HeaderCell>DR #</HeaderCell>
                            <HeaderCell>PO #</HeaderCell>
                            <HeaderCell>Order Date</HeaderCell>
                            <HeaderCell>Status</HeaderCell>
                            <HeaderCell style={{ width: 75 }}>Actions</HeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(orders.isLoading || accounts.isLoading) && <NoResultsRow colSpan={8} text="Loading..." />}
                        {!orders.isLoading && orders.data?.length === 0 && (
                            <NoResultsRow colSpan={8} text="No records found" />
                        )}
                        {(orders.data || []).map((order) => (
                            <TableRow key={order.id}>
                                <TableCell>{order.customerName}</TableCell>
                                <TableCell>TCX{order.id}</TableCell>
                                <TableCell>{order.mcsnAcctnum}</TableCell>
                                <TableCell>{order.custRefnum}</TableCell>
                                <TableCell>{order.custPO}</TableCell>
                                <TableCell>{dayjs(order.orderDate).format('MM/DD/YYYY')}</TableCell>
                                <TableCell>{order.mcsnStatus}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => {
                                            setSelectedInvoice(order.id);
                                        }}
                                    >
                                        Details
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <InvoicePopup invoiceId={selectedInvoice} setInvoiceId={setSelectedInvoice} />
        </Grid2>
    );
}
