import { TableCell, TableRow } from '@mui/material';
import { TitleText } from 'components/Title/Title';
import React from 'react';

export function NoResultsRow({ colSpan, text }: { colSpan: number; text: string }) {
    return (
        <TableRow>
            <TableCell colSpan={colSpan}>
                <div
                    style={{
                        width: '100%',
                        padding: 'calc(50vh - 158px) 0',
                        textAlign: 'center',
                    }}
                >
                    <TitleText style={{ opacity: 0.5 }}>{text}</TitleText>
                </div>
            </TableCell>
        </TableRow>
    );
}
