import config from 'global-variables';
import { useMutation, useQueryClient } from 'react-query';
import { getToken } from './useLogin';
import { OrderLineCreateFields } from 'types/Orders';

async function createOrderLine({
    orderId,
    values,
}: {
    orderId: number;
    values: OrderLineCreateFields;
}): Promise<boolean> {
    const token = getToken();
    if (!token) return false;
    const host = config.restAPIUrl;
    const res = await fetch(`${host}/orders/provider/${orderId}/addItem`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
    });
    if (!res.ok) {
        throw new Error('Failed to create order line');
    }
    await res.text();
    return true;
}

export function useCreateOrderLine() {
    const queryClient = useQueryClient();
    return useMutation(createOrderLine, {
        onSuccess: (_, { orderId }) => {
            queryClient.invalidateQueries(['provider-order-details', orderId.toString()]);
        },
    });
}
