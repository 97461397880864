import React from 'react';
import { TextField, Grid2, Button, FormControl, Paper, Typography, MenuItem } from '@mui/material';
import { useLogin } from 'hooks/useLogin';
import { TitleText } from 'components/Title/Title';
import { Select } from 'components/Select/Select';

export function Login() {
    const login = useLogin();
    const usernameRef = React.useRef<HTMLInputElement>(null);
    const passwordRef = React.useRef<HTMLInputElement>(null);
    const [mode, setMode] = React.useState<'fleet' | 'provider' | 'none'>('none');
    const [lastError, setLastError] = React.useState<string | null>(null);
    const doLogin = () => {
        setLastError(null);
        login.mutate(
            { username: usernameRef.current!.value, password: passwordRef.current!.value, mode },
            {
                onSuccess: () => {
                    alert('Login success');
                },
                onError: (error: any) => {
                    setLastError(error.message);
                },
            },
        );
    };

    return (
        <Paper style={{ maxWidth: 400, margin: '100px auto', padding: 16 }}>
            <Grid2 container spacing={2}>
                <Grid2 size={12}>
                    <TitleText style={{ marginBottom: 0 }}>Sign in</TitleText>
                </Grid2>
                <Grid2 size={12}>
                    <Select
                        variant="outlined"
                        value={mode}
                        onChange={(e) => setMode(e.target.value as 'fleet' | 'provider')}
                        size="small"
                        label="Access Mode"
                        style={{ color: mode === 'none' ? '#999' : undefined }}
                    >
                        <MenuItem value="none" disabled>
                            - select access mode -
                        </MenuItem>
                        <MenuItem value="fleet">ODFL</MenuItem>
                        <MenuItem value="provider">MCSN/Provider</MenuItem>
                    </Select>
                </Grid2>
                <Grid2 size={12}>
                    <FormControl fullWidth>
                        <TextField variant="outlined" size="small" label="Username" inputRef={usernameRef} />
                    </FormControl>
                </Grid2>
                <Grid2 size={12}>
                    <FormControl fullWidth>
                        <TextField
                            variant="outlined"
                            size="small"
                            type="password"
                            label="Password"
                            inputRef={passwordRef}
                        />
                    </FormControl>
                </Grid2>
                {lastError && (
                    <Grid2 size={12}>
                        <Typography color="error">{lastError}</Typography>
                    </Grid2>
                )}
                <Grid2 size={12}>
                    <FormControl fullWidth>
                        <Button variant="contained" onClick={doLogin} disabled={login.isLoading}>
                            Sign in
                        </Button>
                    </FormControl>
                </Grid2>
            </Grid2>
        </Paper>
    );
}
