import { Alert, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface SnackbarData {
    message: React.ReactNode;
    severity: 'success' | 'error' | 'warning' | 'info';
}

let globalSetData: (data: SnackbarData | null) => void = () => {};

export function showSnackbar(data: SnackbarData) {
    if (!globalSetData) return;
    globalSetData(data);
}

export function SnackbarProvider() {
    const [data, setData] = useState<SnackbarData | null>(null);

    useEffect(() => {
        globalSetData = setData;
        return () => {
            globalSetData = () => {};
        };
    }, [setData]);

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            style={{ textAlign: 'center' }}
            open={!!data}
            autoHideDuration={6000}
            onClose={() => setData(null)}
        >
            <Alert severity={data?.severity} variant="filled" sx={{ width: '100%' }}>
                {data?.message}
            </Alert>
        </Snackbar>
    );
}
