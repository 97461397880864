import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createRoot } from 'react-dom/client';
import './index.css';
import { Router } from './Router';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ConfirmDialog } from 'components/Dialogs/ConfirmDialog';
import { SnackbarProvider } from 'components/Snackbar/SnackbarProvider';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

function LaunchApp() {
    return (
        <QueryClientProvider client={queryClient}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Router />
                <ConfirmDialog />
                <SnackbarProvider />
            </LocalizationProvider>
        </QueryClientProvider>
    );
}

const container = document.getElementById('app');
const root = createRoot(container!);
root.render(<LaunchApp />);
