import config from 'global-variables';
import { useQuery } from 'react-query';
import { getToken, logout, useUserData } from './useLogin';
import { MCSNLocation } from 'types/MCSNLocation';

async function getProviderUserAccounts() {
    const token = getToken();
    if (!token) return [];
    const host = config.restAPIUrl;
    const res = await fetch(`${host}/provider-users/accounts`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });
    const data = await res.json();
    if (res.status === 403) {
        logout();
        window.location.reload();
        return [];
    }

    return data as MCSNLocation[];
}

export function useGetProviderUserAccounts() {
    const userData = useUserData();
    return useQuery(['provider-user-accounts', userData?.id], () => getProviderUserAccounts(), {
        refetchInterval: 0,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });
}
