import config from 'global-variables';
import { useQuery } from 'react-query';
import { getToken, logout, useUserData } from './useLogin';
import { Account } from '../types/Account';

async function getFleetUserAccounts() {
    const token = getToken();
    if (!token) return [];
    const host = config.restAPIUrl;
    const res = await fetch(`${host}/users/accounts`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });
    const data = await res.json();
    if (res.status === 403) {
        logout();
        window.location.reload();
        return [];
    }

    return data as Account[];
}

export function useGetFleetUserAccounts() {
    const userData = useUserData();
    return useQuery(['fleet-user-accounts', userData?.id], () => getFleetUserAccounts(), {
        refetchInterval: 0,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });
}
