import React from 'react';
import { Navbar } from './Navbar';
import { useUserData } from 'hooks/useLogin';

function HeaderFont({ children, style }: { children: React.ReactNode; style?: React.CSSProperties }) {
    return (
        <div
            style={{
                textAlign: 'right',
                color: '#27509B',
                fontWeight: 'bold',
                fontSize: 20,
                fontFamily: 'Impact, Haettenschweiler, "Franklin Gothic Bold", "Arial Black", sans-serif',
                ...style,
            }}
        >
            {children}
        </div>
    );
}

export function Header() {
    const userData = useUserData();
    return (
        <>
            <div
                style={{
                    width: '100%',
                    height: 76,
                    backgroundColor: '#FAFAFA',
                    borderBottom: '3px solid #FCE500',
                    position: 'relative',
                    boxShadow: !userData ? '0 2px 2px rgba(0, 0, 0, 0.1)' : undefined,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <img src="/images/logos/logo-header.png" alt="michelin logo" style={{ height: 78 }} />
                    <HeaderFont style={{ paddingRight: 18 }}>
                        {!userData ? (
                            'Fleetorder Portal'
                        ) : (
                            <>
                                {userData.firstName} {userData.lastName}
                                <br />
                                <span style={{ fontStyle: 'italic', fontWeight: 'normal', fontSize: 14 }}>
                                    Env: PROD
                                </span>
                            </>
                        )}
                    </HeaderFont>
                </div>
            </div>
            {userData && <Navbar />}
        </>
    );
}
