import config from 'global-variables';
import { useQuery } from 'react-query';
import { getToken } from './useLogin';
import { User } from '../types/User';

async function getUsersList() {
    const token = getToken();
    if (!token) return [];
    const host = config.restAPIUrl;
    const res = await fetch(`${host}/admin/users`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });
    const data = await res.json();
    return data as User[];
}

export function useGetUsersList() {
    return useQuery(['users-list'], () => getUsersList(), {
        refetchInterval: 0,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });
}
