import config from 'global-variables';
import { useMutation, useQueryClient } from 'react-query';
import { getToken } from './useLogin';
import { OrderRequest } from 'types/Orders';

async function createOrder({ order }: { order: OrderRequest }) {
    const token = getToken();
    if (!token || !order) return null;
    const host = config.restAPIUrl;
    const cleanOrder = {
        ...order,
        newTires: order.newTires
            .filter((tire) => tire && tire.qty && tire.qty !== '0')
            .map((tire) => ({ ...tire, qty: parseInt(tire.qty!, 10) })),
        retreadTires: order.retreadTires
            .filter((tire) => tire && tire.qty && tire.qty !== '0')
            .map((tire) => ({ ...tire, qty: parseInt(tire.qty!, 10) })),
    };

    const res = await fetch(`${host}/orders/create`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(cleanOrder),
    });
    if (!res.ok) {
        const txtRes = await res.text();
        throw new Error('Error creating order: ' + txtRes);
    }
    const data = await res.json();
    return data;
}

export function useCreateOrder() {
    const queryClient = useQueryClient();
    return useMutation((order: OrderRequest) => createOrder({ order }), {
        onSuccess: () => {
            queryClient.invalidateQueries(['pending-orders']);
        },
    });
}
