import config from 'global-variables';
import { useQuery } from 'react-query';
import { getToken, useUserData } from './useLogin';
import { Account } from '../types/Account';

async function getAllAccounts() {
    const token = getToken();
    if (!token) return [];
    const host = config.restAPIUrl;
    const res = await fetch(`${host}/admin/accounts`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });
    const data = await res.json();
    return data as Account[];
}

export function useGetAllAccounts() {
    const userData = useUserData();
    return useQuery(['all-accounts', userData?.id], () => getAllAccounts(), {
        refetchInterval: 0,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });
}
