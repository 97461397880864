import { Typography } from '@mui/material';
import React from 'react';

export function TitleText({ children, style }: { children: React.ReactNode; style?: React.CSSProperties }) {
    return (
        <Typography
            style={{
                fontSize: 24,
                marginBottom: 16,
                fontWeight: 500,
                fontFamily: "'Helvetica Neue', helvetica, arial, sans-serif",
                color: 'rgb(51, 51, 51)',
                ...style,
            }}
        >
            {children}
        </Typography>
    );
}
