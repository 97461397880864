import config from 'global-variables';
import { useMutation, useQueryClient } from 'react-query';
import { getToken } from './useLogin';
import { MCSNInventory, NewInventoryItem } from 'types/MCSNInventory';

async function updateInventory({
    mcsnLocationId,
    newInventory,
}: {
    mcsnLocationId: string;
    newInventory: NewInventoryItem[];
}): Promise<MCSNInventory[]> {
    const token = getToken();
    if (!token || mcsnLocationId === 'none') return [];
    const host = config.restAPIUrl;
    const res = await fetch(`${host}/provider-users/inventory/${mcsnLocationId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newInventory),
    });
    const data = await res.json();
    return data as MCSNInventory[];
}

export function useUpdateInventory() {
    const queryClient = useQueryClient();
    return useMutation(updateInventory, {
        onSuccess: (updatedInventory, data) => {
            queryClient.setQueryData(['inventory', data.mcsnLocationId], updatedInventory);
        },
    });
}
