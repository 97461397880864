import { Button, useMediaQuery, useTheme } from '@mui/material';
import { logout, useUserData } from 'hooks/useLogin';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function NavItem({
    children,
    onClick,
    last,
    selected,
    mode,
}: {
    children: React.ReactNode;
    onClick?: () => void;
    last?: boolean;
    selected?: boolean;
    mode?: 'fleet' | 'provider';
}) {
    const userData = useUserData();
    if (mode && userData?.mode !== mode) return null;

    return (
        <div
            style={{
                padding: '0',
                minWidth: '13vw',
                float: last ? 'right' : 'left',
                height: 20,
                position: 'relative',
                textAlign: 'center',
                backgroundColor: selected ? '#d9edf7' : undefined,
            }}
        >
            <Button
                size="small"
                style={{
                    padding: '0 16px',
                    width: '100%',
                    color: selected ? undefined : '#337ab7',
                    fontWeight: selected ? 'bold' : 'normal',
                }}
                onClick={onClick}
            >
                {children}
            </Button>
        </div>
    );
}

export function Navbar() {
    const userData = useUserData();
    const navigate = useNavigate();
    const theme = useTheme();
    const tabletView = useMediaQuery(theme.breakpoints.down('md'));

    const section = window.location.pathname.split('/').pop();
    if (!userData) return null;
    return (
        <div
            style={{
                color: '#337ab7',
                backgroundColor: '#F5F5F5',
                height: 20,
                borderRight: 'none',
                fontSize: 14,
                padding: 0,
                marginBottom: 16,
                boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
            }}
        >
            <NavItem mode="fleet" onClick={() => navigate('/orders/create')} selected={section === 'create'}>
                {tabletView ? 'New Order' : 'Create New Order'}
            </NavItem>
            <NavItem mode="fleet" onClick={() => navigate('/orders/pending')} selected={section === 'pending'}>
                {tabletView ? 'Pending' : 'Pending Orders'}
            </NavItem>
            <NavItem mode="fleet" onClick={() => navigate('/orders/awaiting')} selected={section === 'awaiting'}>
                {tabletView ? 'Awaiting PO' : 'Orders Awaiting PO'}
            </NavItem>
            <NavItem mode="fleet" onClick={() => navigate('/orders/history')} selected={section === 'history'}>
                {tabletView ? 'History' : 'Order History'}
            </NavItem>

            <NavItem mode="provider" onClick={() => navigate('/my-orders')} selected={section === 'my-orders'}>
                My Orders
            </NavItem>
            <NavItem mode="provider" onClick={() => navigate('/inventory')} selected={section === 'inventory'}>
                Inventory Management
            </NavItem>

            <NavItem last onClick={logout}>
                Log out
            </NavItem>
            {userData.isAdmin && (
                <NavItem mode="fleet" last onClick={() => navigate('/admin/users')} selected={section === 'users'}>
                    Admin
                </NavItem>
            )}
        </div>
    );
}
