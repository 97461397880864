import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface ConfirmDialogData {
    title: string;
    message: React.ReactNode;
    onClose: (ok: boolean) => void;
    alertMode?: boolean;
}

let globalSetData: (data: ConfirmDialogData | null) => void = () => {};

export function confirmDialog(title: string, message: React.ReactNode, alertMode?: boolean) {
    return new Promise<boolean>((resolve) => {
        if (!globalSetData) {
            resolve(false);
            return;
        }
        globalSetData({
            title,
            message,
            alertMode,
            onClose: (ok) => {
                globalSetData(null);
                resolve(ok);
            },
        });
    });
}

export function ConfirmDialog() {
    const [data, setData] = useState<ConfirmDialogData | null>(null);

    useEffect(() => {
        globalSetData = setData;
        return () => {
            globalSetData = () => {};
        };
    }, [setData]);

    return (
        <Dialog open={!!data} maxWidth="lg">
            <DialogTitle style={{ minWidth: 400 }}>{data?.title}</DialogTitle>
            <div style={{ padding: '8px  24px' }}>{data?.message}</div>
            <DialogActions style={{ padding: '16px' }}>
                {!data?.alertMode && (
                    <Button onClick={() => data?.onClose(false)} variant="contained" color="error">
                        Cancel
                    </Button>
                )}
                <Button onClick={() => data?.onClose(true)} variant="contained">
                    {!data?.alertMode ? 'Confirm' : 'OK'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
