import { Close } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControlLabel,
    FormLabel,
    Grid2,
    IconButton,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { LocationsMultiselect } from 'components/LocationsMultiselect/LocationsMultiselect';
import { PasswordInput } from 'components/PasswordInput/PasswordInput';
import { TitleText } from 'components/Title/Title';
import { useCreateUser } from 'hooks/useCreateUser';
import { useGetAllAccounts } from 'hooks/useGetAllAccounts';
import { useGetUser } from 'hooks/useGetUser';
import { useUpdateUser } from 'hooks/useUpdateUser';
import React, { useEffect } from 'react';
import { Control, Controller, useForm } from 'react-hook-form';
import { User } from 'types/User';

function PermissionInput({
    label,
    name,
    control,
}: {
    label: string;
    name: 'canPO' | 'isAdmin' | 'showPrice';
    control: Control<User, any>;
}) {
    return (
        <Grid2 size={{ xs: 12, sm: 4 }} sx={{ textAlign: 'center' }}>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <FormControlLabel
                        control={<Switch {...field} checked={field.value} onChange={(_, v) => field.onChange(v)} />}
                        label={label}
                    />
                )}
            />
        </Grid2>
    );
}

export function UserPopup({ userId, setUserId }: { userId?: string; setUserId: (value?: string) => void }) {
    const user = useGetUser(userId);
    const newUser = userId === 'new';
    const updateUser = useUpdateUser();
    const createUser = useCreateUser();
    const locations = useGetAllAccounts();
    const [error, setError] = React.useState<string | undefined>(undefined);

    const { control, reset, getValues, handleSubmit } = useForm<User>({
        defaultValues: {
            canPO: false,
            isAdmin: false,
            showPrice: false,
            acctList: '',
        },
    });
    useEffect(() => {
        if (user.isLoading) return;
        reset(user.data || {});
    }, [user.data, user.isLoading, userId, reset]);

    const close = () => {
        if (updateUser.isLoading) return;
        setError(undefined);
        setUserId(undefined);
    };

    const save = () => {
        setError(undefined);
        if (user.isLoading || updateUser.isLoading) return;
        const mutate = newUser ? createUser.mutate : updateUser.mutate;
        mutate(getValues(), {
            onSuccess: close,
            onError: (e: any) => {
                if (typeof e.message === 'string') {
                    setError(e.message);
                } else {
                    setError('Error saving user, please check the fields and try again.');
                }
            },
        });
    };

    return (
        <Dialog open={!!userId} maxWidth="md">
            <DialogTitle>
                {newUser ? 'Create new user' : 'Edit user'}
                <IconButton style={{ position: 'absolute', top: 12, right: 12 }} onClick={close}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <div style={{ padding: 24 }}>
                {(user.isLoading || locations.isLoading) && (
                    <div style={{ padding: 48, width: 500, textAlign: 'center' }}>
                        <TitleText style={{ opacity: 0.5 }}>Loading...</TitleText>
                    </div>
                )}
                {!user.isLoading && (
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, md: 6 }}>
                            <Controller
                                name="odEmpNum"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        type="text"
                                        required
                                        label="Employee Number"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        error={!field.value && error !== undefined}
                                    />
                                )}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 8 }}>
                            <Controller
                                name="username"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        required
                                        disabled={!newUser}
                                        slotProps={{
                                            input: {
                                                readOnly: !newUser,
                                            },
                                        }}
                                        label="Username"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        error={!field.value && error !== undefined}
                                    />
                                )}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 4 }}>
                            <Controller
                                name="password"
                                control={control}
                                rules={{ required: newUser }}
                                render={({ field }) => (
                                    <PasswordInput
                                        field={field}
                                        startDisabled={!newUser}
                                        setValue={(pass) => field.onChange(pass)}
                                        value={field.value}
                                    />
                                )}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6 }}>
                            <Controller
                                name="lastName"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        required
                                        label="Last Name"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        error={!field.value && error !== undefined}
                                    />
                                )}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6 }}>
                            <Controller
                                name="firstName"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        required
                                        label="First Name"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        error={!field.value && error !== undefined}
                                    />
                                )}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                            <FormLabel component="legend">Permissions</FormLabel>
                            <Grid2 container spacing={2}>
                                <PermissionInput label="User can submit a PO" name="canPO" control={control} />
                                <PermissionInput label="User is an Admin" name="isAdmin" control={control} />
                                <PermissionInput label="User can see Prices" name="showPrice" control={control} />
                            </Grid2>
                        </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                            <Controller
                                name="acctList"
                                control={control}
                                render={({ field }) => (
                                    <LocationsMultiselect
                                        locations={locations.data || []}
                                        value={field.value.split(',')}
                                        onChange={(v) => field.onChange(v.join(','))}
                                    />
                                )}
                            />
                        </Grid2>
                    </Grid2>
                )}
            </div>
            <DialogActions sx={{ padding: '24px' }}>
                {error && (
                    <div style={{ width: '100%' }}>
                        <Typography color="error">{error}</Typography>
                    </div>
                )}
                <Button disabled={updateUser.isLoading} onClick={close} variant="contained" color="error">
                    Close
                </Button>
                <Button
                    disabled={updateUser.isLoading}
                    onClick={() => {
                        handleSubmit(save, () => {
                            setError('Error saving user, please check the required fields and try again.');
                        })();
                    }}
                    variant="contained"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
