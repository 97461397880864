import config from 'global-variables';
import { useMutation, useQueryClient } from 'react-query';
import { getToken } from './useLogin';
import { User } from '../types/User';

async function updateUser({ user }: { user: User }) {
    const token = getToken();
    if (!token || !user) return null;
    if (!user.id) return null;
    const host = config.restAPIUrl;
    const res = await fetch(`${host}/admin/users/${user.id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(user),
    });
    if (!res.ok) {
        const txtRes = await res.text();
        throw new Error('Error updating user: ' + txtRes);
    }
    const data = await res.json();
    return data;
}

export function useUpdateUser() {
    const queryClient = useQueryClient();
    return useMutation((user: User) => updateUser({ user }), {
        onSuccess: (_, data) => {
            queryClient.invalidateQueries(['user-data', data.id]);
            queryClient.invalidateQueries(['users-list']);
        },
    });
}
