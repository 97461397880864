import config from 'global-variables';
import { useQuery } from 'react-query';
import { getToken } from './useLogin';
import { MCSNInventory } from 'types/MCSNInventory';

async function getProviderOrdersAvailableItems({
    mcsnLocationId,
}: {
    mcsnLocationId?: string;
}): Promise<MCSNInventory[]> {
    const token = getToken();
    if (!token || !mcsnLocationId || mcsnLocationId === 'none') return [];
    const host = config.restAPIUrl;
    const res = await fetch(`${host}/orders/provider/available-items/${mcsnLocationId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });
    const data = await res.json();
    return data as MCSNInventory[];
}

export function useGetProviderOrdersAvailableItems(mcsnLocationId?: string) {
    return useQuery(
        ['order-available-items', mcsnLocationId],
        () => getProviderOrdersAvailableItems({ mcsnLocationId }),
        {
            refetchInterval: 0,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        },
    );
}
