import config from 'global-variables';
import { useQuery } from 'react-query';
import { getToken } from './useLogin';
import { ProviderOrder } from 'types/Orders';

async function getProviderOrders({ mcsnLocationId }: { mcsnLocationId?: string }): Promise<ProviderOrder[]> {
    const token = getToken();
    if (!token || mcsnLocationId === 'none') return [];
    const host = config.restAPIUrl;
    const res = await fetch(`${host}/orders/provider/${mcsnLocationId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });
    const data = await res.json();
    return data as ProviderOrder[];
}

export function useGetProviderOrders(mcsnLocationId: string) {
    return useQuery(['provider-orders', mcsnLocationId], () => getProviderOrders({ mcsnLocationId }), {
        refetchInterval: 0,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });
}
