import {
    Button,
    Grid2,
    MenuItem,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import React, { useRef } from 'react';
import dayjs from 'dayjs';
import { AccountSelector } from 'components/AccountSelector/AccountSelector';
import { NoResultsRow } from 'components/NoResultsRow/NoResultsRow';
import { useGetProviderOrders } from 'hooks/useGetProviderOrders';
import { useGetProviderUserAccounts } from 'hooks/useGetProviderUserAccounts';
import { ProviderOrderPopup } from 'components/ProviderOrderPopup/ProviderOrderPopup';
import { confirmDialog } from 'components/Dialogs/ConfirmDialog';
import { Select } from 'components/Select/Select';
import { useGetODFLLocations } from 'hooks/useGetODFLLocations';
import { useCreateScrapOutOrder } from 'hooks/useCreateScrapOutOrder';
import { showSnackbar } from 'components/Snackbar/SnackbarProvider';

function CreateOrderButton({ selectedAccount }: { selectedAccount: string }) {
    const odflLocations = useGetODFLLocations(selectedAccount);
    const createScrapOutOrder = useCreateScrapOutOrder();
    const selectedODFLLocation = useRef<string>('');

    return (
        <Button
            fullWidth
            disabled={selectedAccount === 'none'}
            variant="contained"
            onClick={async () => {
                selectedODFLLocation.current = 'none';
                const res = await confirmDialog(
                    'Create ODFL Scrap out Order',
                    <>
                        <Select
                            fullWidth
                            label="ODFL Location"
                            size="small"
                            defaultValue={selectedODFLLocation.current}
                            onChange={(e) => {
                                selectedODFLLocation.current = e.target.value;
                            }}
                        >
                            <MenuItem value="none" disabled style={{ opacity: 0.5 }}>
                                <i>-select a location-</i>
                            </MenuItem>
                            {odflLocations.data?.map(({ id, customerName }) => (
                                <MenuItem key={id} value={id}>
                                    {customerName}
                                </MenuItem>
                            ))}
                        </Select>
                    </>,
                );
                if (res) {
                    createScrapOutOrder.mutate(
                        {
                            odflLocationId: parseInt(selectedODFLLocation.current, 10),
                            mcsnLocationId: parseInt(selectedAccount, 10),
                        },
                        {
                            onSuccess: () => {
                                showSnackbar({
                                    message: 'Scrap Out Order created successfully',
                                    severity: 'success',
                                });
                            },
                            onError: (error: any) => {
                                showSnackbar({
                                    message: `Error creating scrap out order: ${error.message}`,
                                    severity: 'error',
                                });
                            },
                        },
                    );
                }
            }}
        >
            Create ODFL Scrap out Order
        </Button>
    );
}

const HeaderCell = styled(TableCell)(() => ({
    fontWeight: 'bold',
    backgroundColor: '#F5F5F5',
}));

export function ProviderOrders() {
    const accounts = useGetProviderUserAccounts();
    const [selectedAccount, setSelectedAccount] = React.useState<string>('none');
    const [selectedOrder, setSelectedOrder] = React.useState<string | undefined>(undefined);
    const orders = useGetProviderOrders(selectedAccount);

    return (
        <Grid2 container spacing={2} style={{ padding: '0 24px' }}>
            <Grid2 size={{ xs: 12, sm: 7, md: 8, lg: 9 }}>
                <AccountSelector
                    label="Filter by Location"
                    value={selectedAccount}
                    onChange={setSelectedAccount}
                    accounts={accounts.data || []}
                    labelField="mcsnDCDescr"
                />
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 5, md: 4, lg: 3 }}>
                <CreateOrderButton selectedAccount={selectedAccount} />
            </Grid2>
            <TableContainer component={Paper} style={{ marginBottom: 24 }}>
                <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <HeaderCell>Customer</HeaderCell>
                            <HeaderCell>Location</HeaderCell>
                            <HeaderCell>Order Date</HeaderCell>
                            <HeaderCell>Ref #</HeaderCell>
                            <HeaderCell>PO #</HeaderCell>
                            <HeaderCell>Status</HeaderCell>
                            <HeaderCell style={{ width: 75 }}>Actions</HeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(orders.isLoading || accounts.isLoading) && <NoResultsRow colSpan={8} text="Loading..." />}
                        {!orders.isLoading && orders.data?.length === 0 && (
                            <NoResultsRow
                                colSpan={8}
                                text={
                                    selectedAccount !== 'none'
                                        ? 'No orders found for this account'
                                        : 'Select an account to view orders'
                                }
                            />
                        )}
                        {(orders.data || []).map((order) => (
                            <TableRow key={order.id}>
                                <TableCell>{order.customerName}</TableCell>
                                <TableCell>{order.locationName}</TableCell>
                                <TableCell>{dayjs(order.orderDate).format('MM/DD/YYYY')}</TableCell>
                                <TableCell>{order.custRefnum}</TableCell>
                                <TableCell>{order.custPO}</TableCell>
                                <TableCell>{order.mcsnStatus}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => {
                                            setSelectedOrder(order.id.toString());
                                        }}
                                    >
                                        View
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <ProviderOrderPopup orderId={selectedOrder} setOrderId={setSelectedOrder} />
        </Grid2>
    );
}
