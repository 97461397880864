interface GlobalConfig {
    restAPIUrl: string;
    // no testing or staging on this project
    env: 'local' | 'dev' | 'prod'; // 'test' | 'stg';
}

const allConfigs: {
    localhost: GlobalConfig;
    dev: GlobalConfig;
    // test: GlobalConfig;
    // staging: GlobalConfig;
    prod: GlobalConfig;
} = {
    localhost: {
        restAPIUrl: 'http://localhost:3030',
        env: 'local',
    },
    dev: {
        restAPIUrl: 'https://api.odfl-fleetorder.integration.misp-solutions.com',
        env: 'dev',
    },
    // test: {
    //     restAPIUrl: 'https://api.odfl-test.misp-solutions.com',
    //     env: 'test',
    // },
    // staging: {
    //     restAPIUrl: 'https://api.odfl-staging.misp-solutions.com',
    //     env: 'stg',
    // },
    prod: {
        restAPIUrl: 'https://api.odfl-fleetorder.misp-solutions.com',
        env: 'prod',
    },
};

let config: GlobalConfig;
if (window.location.hostname.includes('localhost') || window.location.hostname.includes('.chaos')) {
    config = allConfigs.localhost;
} else if (window.location.hostname.includes('.integration.')) {
    config = allConfigs.dev;
    // } else if (window.location.hostname.includes('.test.')) {
    //     config = allConfigs.test;
    // } else if (window.location.hostname.includes('.staging.')) {
    //     config = allConfigs.staging;
} else {
    config = allConfigs.prod;
}

export default config;
