import { Close } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogTitle,
    Grid2,
    IconButton,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import { showSnackbar } from 'components/Snackbar/SnackbarProvider';
import { useGetInvoice } from 'hooks/useGetInvoice';
import { useUserData } from 'hooks/useLogin';
import { useUpdateOrderPO } from 'hooks/useUpdateOrderPO';
import React, { useEffect } from 'react';

const HeaderCell = styled(TableCell)(() => ({
    fontWeight: 'bold',
    backgroundColor: '#F5F5F5',
}));

export function InvoicePopup({
    invoiceId,
    setInvoiceId,
}: {
    invoiceId?: string;
    setInvoiceId: (value?: string) => void;
}) {
    const userData = useUserData();
    const invoice = useGetInvoice(invoiceId);
    const setPO = useUpdateOrderPO();
    const [poNumber, setPoNumber] = React.useState('');
    useEffect(() => setPoNumber(''), [invoiceId]);

    const padding = 48;
    const close = () => setInvoiceId(undefined);
    return (
        <Dialog open={!!invoiceId} maxWidth="lg" onClose={close}>
            <DialogTitle>
                Invoice Details
                <IconButton style={{ position: 'absolute', top: 12, right: 12 }} onClick={close}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <TableContainer component={Paper} style={{ width: '100%', paddingBottom: 16 }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <HeaderCell width={70} style={{ paddingLeft: padding }}>
                                PN
                            </HeaderCell>
                            <HeaderCell width={370}>Description</HeaderCell>
                            <HeaderCell width={70} align="center">
                                Quantity
                            </HeaderCell>
                            <HeaderCell width={70} align="right" style={{ paddingRight: padding }}>
                                CustPN
                            </HeaderCell>
                            {userData?.showPrice && <HeaderCell align="right">Price/Unit</HeaderCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoice.data?.lines.map((item) => (
                            <TableRow
                                key={item.custPN}
                                style={{
                                    backgroundColor: item.custPN !== item.mcsnPartNum ? '#FFEDF2' : '#FFFFFF',
                                }}
                            >
                                <TableCell style={{ paddingLeft: padding }}>{item.partNum}</TableCell>
                                <TableCell>{item.partDescription}</TableCell>
                                <TableCell align="center">{item.quantity}</TableCell>
                                <TableCell align="right" style={{ paddingRight: padding }}>
                                    {item.custPN}
                                </TableCell>
                                {userData?.showPrice && (
                                    <TableCell align="right">{item.price ? `$ ${item.price}` : 'N/A'}</TableCell>
                                )}
                            </TableRow>
                        ))}
                        {userData?.showPrice && (
                            <TableRow>
                                <TableCell colSpan={3} />
                                <TableCell align="right" style={{ paddingRight: padding }}>
                                    <b>Total</b>
                                </TableCell>
                                <TableCell align="right">
                                    <b>
                                        {'$ '}
                                        {invoice.data?.lines
                                            ?.reduce(
                                                (acc, item) => acc + parseFloat(item.price || '0') * item.quantity,
                                                0,
                                            )
                                            .toFixed(2)}
                                    </b>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {invoice.data?.mcsnStatus === 'PENDING PO' && userData?.canPO && (
                <Grid2 container spacing={2} style={{ padding: 16 }}>
                    <Grid2 size={{ xs: 8 }}>
                        <TextField
                            size="small"
                            fullWidth
                            label="Enter PO Number"
                            value={poNumber}
                            onChange={(e) => setPoNumber(e.target.value)}
                        />
                    </Grid2>
                    <Grid2 size={{ xs: 4 }}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                if (invoiceId) {
                                    setPO.mutate(
                                        { orderId: parseInt(invoiceId), poNumber },
                                        {
                                            onSuccess: () => {
                                                setInvoiceId('');
                                                close();
                                                showSnackbar({
                                                    message: 'PO Number updated successfully',
                                                    severity: 'success',
                                                });
                                            },
                                            onError: (error: any) => {
                                                showSnackbar({
                                                    message: `Error: ${error.message}`,
                                                    severity: 'error',
                                                });
                                            },
                                        },
                                    );
                                }
                            }}
                        >
                            Submit
                        </Button>
                    </Grid2>
                </Grid2>
            )}
        </Dialog>
    );
}
