import { MenuItem } from '@mui/material';
import { Select } from 'components/Select/Select';
import React from 'react';

export function AccountSelector<T extends { id: number }>({
    value,
    onChange,
    accounts,
    noSelectionText = '-Select a location-',
    label,
    labelField,
}: {
    value: string;
    onChange: (newValue: string) => void;
    accounts: T[];
    noSelectionText?: string;
    label?: string;
    labelField: keyof T;
}) {
    return (
        <Select
            label={label}
            value={value}
            onChange={(e) => onChange(e.target.value.toString())}
            size="small"
            style={value === 'none' ? { color: 'gray', fontStyle: 'italic' } : undefined}
        >
            <MenuItem value="none" style={{ opacity: 0.5, fontStyle: 'italic' }}>
                {noSelectionText}
            </MenuItem>
            {accounts?.map((account) => (
                <MenuItem key={account.id} value={account.id}>
                    {account[labelField] as string}
                </MenuItem>
            ))}
        </Select>
    );
}
