import config from 'global-variables';
import { useQuery } from 'react-query';
import { getToken } from './useLogin';
import { User } from '../types/User';

async function getUser({ userId }: { userId?: string }) {
    const token = getToken();
    if (!token || !userId) return null;
    if (userId === 'new') return {} as User;
    const host = config.restAPIUrl;
    const res = await fetch(`${host}/admin/users/${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });
    const data = await res.json();
    return data as User;
}

export function useGetUser(userId?: string) {
    return useQuery(['user-data', userId], () => getUser({ userId }), {
        refetchInterval: 0,
        refetchOnWindowFocus: false,
        keepPreviousData: false,
    });
}
