import { Delete, Edit } from '@mui/icons-material';
import {
    Button,
    Grid2,
    IconButton,
    MenuItem,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import { confirmDialog } from 'components/Dialogs/ConfirmDialog';
import { showSnackbar } from 'components/Snackbar/SnackbarProvider';
import { useDeleteOrderLine } from 'hooks/useDeleteOrderLine';
import React, { useState } from 'react';
import { OrderLineEditFields, ProviderOrderDetails } from 'types/Orders';
import { EditOrderLinePopup } from './EditOrderLinePopup';
import { useGetProviderOrdersAvailableItems } from 'hooks/useGetProviderOrdersAvailableItems';
import { Select } from 'components/Select/Select';
import { MCSNInventory } from 'types/MCSNInventory';
import { useCreateOrderLine } from 'hooks/useCreateOrderLine';
import { TitledContainer } from 'components/TitledContainer/TitledContainer';

const HeaderCell = styled(TableCell)(() => ({
    fontWeight: 'bold',
    backgroundColor: '#F5F5F5',
}));

function AddItemRow({ mcsnLocationId, orderId }: { mcsnLocationId: string; orderId: number }) {
    const availableItems = useGetProviderOrdersAvailableItems(mcsnLocationId);
    const [selectedItem, setSelectedItem] = useState<MCSNInventory | null>(null);
    const [qty, setQty] = useState('0');
    const createOrderLine = useCreateOrderLine();
    if (!availableItems.data) return null;

    const save = () => {
        if (!selectedItem) return;
        createOrderLine.mutate(
            {
                orderId,
                values: {
                    mcsnPartNum: selectedItem.mcsnPartNum,
                    qty: parseInt(qty, 10),
                },
            },
            {
                onSuccess: () => {
                    showSnackbar({
                        message: 'Item added successfully',
                        severity: 'success',
                    });
                    setQty('0');
                    setSelectedItem(null);
                },
                onError: () => {
                    showSnackbar({
                        message: 'Failed to add item',
                        severity: 'error',
                    });
                },
            },
        );
    };

    return (
        <TitledContainer title="Add Part to Order">
            <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12, sm: 8, md: 9 }}>
                    <Select
                        size="small"
                        fullWidth
                        label="Item"
                        value={selectedItem?.mcsnPartNum || 'none'}
                        onChange={(e) =>
                            setSelectedItem(availableItems.data.find((i) => i.mcsnPartNum === e.target.value) || null)
                        }
                    >
                        <MenuItem value="none" disabled>
                            <i style={{ opacity: 0.5 }}>- select an item -</i>
                        </MenuItem>
                        {availableItems.data.map((item) => (
                            <MenuItem key={item.mcsnPartNum} value={item.mcsnPartNum}>
                                <b>{item.mcsnPartNum}</b>
                                <i style={{ marginLeft: 16 }}>{item.mcsnPartDesc}</i>
                            </MenuItem>
                        ))}
                    </Select>
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 2, md: 2 }}>
                    <TextField
                        fullWidth
                        size="small"
                        type="number"
                        label="Quantity"
                        value={qty}
                        onChange={(e) => setQty(e.target.value)}
                        disabled={!selectedItem}
                        slotProps={{
                            htmlInput() {
                                return {
                                    min: 0,
                                    step: 1,
                                };
                            },
                        }}
                    />
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 2, md: 1 }}>
                    <Button fullWidth variant="contained" onClick={save} disabled={!selectedItem || qty === '0'}>
                        Add
                    </Button>
                </Grid2>
            </Grid2>
        </TitledContainer>
    );
}

export function OrderDetailsTable({ order }: { order?: ProviderOrderDetails | null }) {
    const deleteOrderLine = useDeleteOrderLine();
    const [lineEditValue, setLineEditValue] = useState<{
        value: OrderLineEditFields;
        orderLineId: number;
        itemDescription: string;
    } | null>(null);
    const padding = 48;
    if (!order) return null;

    return (
        <>
            <AddItemRow mcsnLocationId={order?.mcsnLocationId.toString()} orderId={order.id} />
            <TableContainer component={Paper} style={{ paddingBottom: 16, marginTop: 16 }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <HeaderCell width={70} style={{ paddingLeft: padding }}>
                                Part #
                            </HeaderCell>
                            <HeaderCell width={370}>Description</HeaderCell>
                            <HeaderCell width={70} align="center">
                                Quantity
                            </HeaderCell>
                            <HeaderCell width={100}>Mount Type</HeaderCell>
                            <HeaderCell width={70}>Part Type</HeaderCell>
                            <HeaderCell width={70} align="center">
                                Actions
                            </HeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {order.lines.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell style={{ paddingLeft: padding }}>{item.mcsnPartNum}</TableCell>
                                <TableCell>{item.mcsnPartDesc}</TableCell>
                                <TableCell align="center">{item.qty}</TableCell>
                                <TableCell>{item.qtyType}</TableCell>
                                <TableCell>{item.mcsnPartNum[0] === 'Z' ? 'Services' : 'Tire'}</TableCell>
                                <TableCell align="center">
                                    <IconButton
                                        onClick={() => {
                                            setLineEditValue({
                                                value: {
                                                    qty: item.qty,
                                                    qtyType: item.qtyType,
                                                },
                                                orderLineId: item.id,
                                                itemDescription: item.mcsnPartDesc,
                                            });
                                        }}
                                    >
                                        <Edit />
                                    </IconButton>
                                    <IconButton
                                        onClick={async () => {
                                            const res = await confirmDialog(
                                                'Are you sure?',
                                                <>
                                                    Do you want to remove{' '}
                                                    <b>
                                                        {item.qty} {item.qtyType || ''} {item.mcsnPartDesc}
                                                    </b>{' '}
                                                    from the order?
                                                </>,
                                            );
                                            if (!res) return;
                                            deleteOrderLine.mutate(
                                                {
                                                    orderId: order.id,
                                                    orderLineId: item.id,
                                                },
                                                {
                                                    onSuccess: () => {
                                                        showSnackbar({
                                                            message: 'Item removed successfully',
                                                            severity: 'success',
                                                        });
                                                    },
                                                    onError: () => {
                                                        showSnackbar({
                                                            message: 'Failed to remove item',
                                                            severity: 'error',
                                                        });
                                                    },
                                                },
                                            );
                                        }}
                                    >
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {lineEditValue && (
                <EditOrderLinePopup
                    orderId={order.id}
                    orderLineId={lineEditValue.orderLineId}
                    value={lineEditValue.value}
                    itemDescription={lineEditValue.itemDescription}
                    onClose={() => setLineEditValue(null)}
                />
            )}
        </>
    );
}
