import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogTitle, Grid2, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import { Select } from 'components/Select/Select';
import dayjs from 'dayjs';
import { useGetProviderOrderDetails } from 'hooks/useGetProviderOrderDetails';
import React, { useEffect } from 'react';
import { OrderHeaderEditFields, orderStatuses } from 'types/Orders';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { OrderDetailsTable } from './OrderDetailsTable';
import { useUpdateProviderOrderDetails } from 'hooks/useUpdateProviderOrderDetails';
import { showSnackbar } from 'components/Snackbar/SnackbarProvider';
import { TitledContainer } from 'components/TitledContainer/TitledContainer';

export function ProviderOrderPopup({
    orderId,
    setOrderId,
}: {
    orderId?: string;
    setOrderId: (value?: string) => void;
}) {
    const order = useGetProviderOrderDetails(orderId);
    const updateOrderDetails = useUpdateProviderOrderDetails();
    const { control, reset, handleSubmit } = useForm<OrderHeaderEditFields>({
        defaultValues: {
            mcsnStatus: '',
            mcsnInvnum: '',
            custRefnum: '',
        },
    });
    const { isDirty } = useFormState({ control });

    const save = (data: OrderHeaderEditFields) => {
        if (!orderId) return;
        updateOrderDetails.mutate(
            { orderId: parseInt(orderId, 10), newValues: data },
            {
                onSuccess: () => {
                    showSnackbar({
                        message: 'Order updated',
                        severity: 'success',
                    });
                },
                onError: () => {
                    showSnackbar({
                        message: 'Error updating order',
                        severity: 'error',
                    });
                },
            },
        );
    };

    useEffect(() => {
        reset({
            mcsnStatus: order.data?.mcsnStatus || '',
            mcsnInvnum: order.data?.mcsnInvnum || '',
            custRefnum: order.data?.custRefnum || '',
        });
    }, [order.data, reset]);

    const close = () => setOrderId(undefined);
    return (
        <Dialog open={!!orderId} maxWidth="xl" onClose={close}>
            <DialogTitle>
                Fleet Order Details
                <IconButton style={{ position: 'absolute', top: 12, right: 12 }} onClick={close}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <Grid2 container spacing={2} style={{ padding: 16 }}>
                <Grid2 size={{ xs: 12 }}>
                    <Typography variant="body1">
                        Acctnum <b>{order.data?.mcsnAcctnum}</b> - Order submitted{' '}
                        <b>{dayjs(order.data?.orderDate).format('MM-DD-YY')}</b>
                    </Typography>
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                    <Typography variant="body2">
                        Punchout Order# <b>{order.data?.id}</b>
                    </Typography>
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                    <Typography variant="body2">
                        PO# <b>{order.data?.custPO}</b>
                    </Typography>
                </Grid2>
                <Grid2 size={{ xs: 12 }}>
                    <TitledContainer title="Order Status">
                        <Grid2 container spacing={2}>
                            <Grid2 size={{ xs: 12, sm: 10, md: 11 }}>
                                <Grid2 container spacing={2}>
                                    <Grid2 size={{ xs: 12, sm: 4 }}>
                                        <Controller
                                            name="mcsnInvnum"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField {...field} label="ERP Order#" size="small" fullWidth />
                                            )}
                                        />
                                    </Grid2>
                                    <Grid2 size={{ xs: 12, sm: 4 }}>
                                        <Controller
                                            name="mcsnStatus"
                                            control={control}
                                            render={({ field }) => (
                                                <Select {...field} fullWidth label="Status" size="small">
                                                    {orderStatuses.map((status) => (
                                                        <MenuItem key={status} value={status}>
                                                            {status}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                    </Grid2>
                                    <Grid2 size={{ xs: 12, sm: 4 }}>
                                        <Controller
                                            name="custRefnum"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField {...field} label="DR #" size="small" fullWidth />
                                            )}
                                        />
                                    </Grid2>
                                </Grid2>
                            </Grid2>
                            <Grid2 size={{ xs: 12, sm: 2, md: 1 }}>
                                <Button fullWidth variant="contained" disabled={!isDirty} onClick={handleSubmit(save)}>
                                    Save
                                </Button>
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <TextField
                                    label="Customer Comments"
                                    size="small"
                                    fullWidth
                                    multiline
                                    disabled
                                    minRows={3}
                                    value={order.data?.custNotes}
                                />
                            </Grid2>
                        </Grid2>
                    </TitledContainer>
                </Grid2>

                <Grid2 size={{ xs: 12 }} sx={{}}>
                    <OrderDetailsTable order={order.data} />
                </Grid2>
            </Grid2>
        </Dialog>
    );
}
