export const orderStatuses = ['SUBMITTED', 'RECEIVED', 'SCHEDULED', 'PENDING PO', 'PO RECEIVED', 'INVOICED', 'VOIDED'];

export type OrderStatus = 'SUBMITTED' | 'RECEIVED' | 'SCHEDULED' | 'PENDING PO' | 'PO RECEIVED' | 'INVOICED' | 'VOIDED';

export interface PendingOrder {
    orderDate: string;
    mcsnStatus: OrderStatus;
    id: string;
    custRefnum: string;
    custPO: string;
    mcsnAcctnum: string;
    customerName: string;
    orderType?: string;
}

export interface OpenOrder {
    orderType: string;
    id: number;
    mcsnAcctnum: string;
    customerName: string;
    customerAddress: string;
    customerCity: string;
    customerState: string;
    customerZip: string;
    orderDate: string;
}

export interface OrderRequest {
    newTires: {
        inventoryId: number;
        qty?: string;
        qtyType: string | null;
    }[];
    retreadTires: {
        inventoryId: number;
        qty?: string;
        qtyType: string | null;
    }[];
    customerNotes: string;
    accountID: number;
}

export interface ProviderOrder {
    id: number;
    locationName: string;
    customerName: string;
    custRefnum: string;
    custPO: string;
    orderDate: string;
    mcsnStatus: OrderStatus;
}

export interface ProviderOrderDetailsLine {
    id: number;
    mcsnOrderId: number;
    mcsnPartNum: string;
    mcsnPartDesc: string;
    qty: number;
    qtyType: string | null;
}

export interface ProviderOrderDetails {
    id: number;
    locationId: number;
    orderDate: string;
    custRefnum: string | null;
    custPO: string | null;
    custNotes: string | null;
    mcsnLocationId: number;
    mcsnProvider: string;
    mcsnAcctnum: string;
    mcsnInvnum: string;
    mcsnStatus: OrderStatus;
    lines: ProviderOrderDetailsLine[];
}

export interface OrderLineEditFields {
    qty: number;
    qtyType: string | null;
}

export interface OrderLineCreateFields {
    qty: number;
    mcsnPartNum: string;
}

export interface OrderHeaderEditFields {
    mcsnStatus: string;
    mcsnInvnum: string;
    custRefnum: string;
}

export interface ScrapOutOrderRequest {
    mcsnLocationId: number;
    odflLocationId: number;
}
