import { Paper, styled, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';

const HeaderCell = styled(TableCell)(() => ({
    fontWeight: 'bold',
    backgroundColor: '#F5F5F5',
}));

export function TitledContainer({ title, children }: { title: string; children: React.ReactNode }) {
    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <HeaderCell width={70} style={{ paddingLeft: 48 }}>
                            {title}
                        </HeaderCell>
                    </TableRow>
                </TableHead>
                <TableRow>
                    <TableCell style={{ padding: 16 }}>{children}</TableCell>
                </TableRow>
            </Table>
        </TableContainer>
    );
}
