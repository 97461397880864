import {
    Button,
    Grid2,
    Icon,
    IconButton,
    InputAdornment,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import React from 'react';
import { NoResultsRow } from 'components/NoResultsRow/NoResultsRow';
import { Edit } from '@mui/icons-material';
import { useGetUsersList } from 'hooks/useGetUsersList';
import { User } from 'types/User';
import { UserPopup } from 'components/UserPopup/UserPopup';
import { useDisableUser } from 'hooks/useDisableUser';
import { confirmDialog } from 'components/Dialogs/ConfirmDialog';

const HeaderCell = styled(TableCell)(() => ({
    fontWeight: 'bold',
    backgroundColor: '#F5F5F5',
}));

function getSearchText(user: User) {
    return `${user.odEmpNum} ${user.username} ${user.firstName} ${user.lastName}`.toLocaleLowerCase();
}

export function UserList() {
    const users = useGetUsersList();
    const disableUser = useDisableUser();
    const [userId, setUserId] = React.useState<string | undefined>(undefined);
    const [filter, setFilter] = React.useState('');
    const filteredData = React.useMemo(() => {
        if (!filter) {
            return users.data || [];
        }
        if (!users.data) return [];
        return users.data?.filter((user) => {
            return getSearchText(user).includes(filter.toLocaleLowerCase());
        });
    }, [filter, users.data]);

    return (
        <Grid2 container spacing={2} style={{ padding: '0 24px', marginTop: 32 }}>
            <Grid2 size={{ xs: 12, md: 6, lg: 8 }} />
            <Grid2 size={{ xs: 12, md: 6, lg: 4 }}>
                <Grid2 container spacing={2}>
                    <Grid2 size={{ xs: 12, sm: 8 }}>
                        <TextField
                            label="Filter"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon>search</Icon>
                                        </InputAdornment>
                                    ),
                                },
                            }}
                        />
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 4 }}>
                        <Button variant="contained" style={{ width: '100%' }} onClick={() => setUserId('new')}>
                            New User
                        </Button>
                    </Grid2>
                </Grid2>
            </Grid2>
            <TableContainer component={Paper} style={{ marginBottom: 24 }}>
                <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <HeaderCell>Emp#</HeaderCell>
                            <HeaderCell>Username</HeaderCell>
                            <HeaderCell>Name</HeaderCell>
                            <HeaderCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                                Can PO
                            </HeaderCell>
                            <HeaderCell align="center">Admin</HeaderCell>
                            <HeaderCell align="center" width={70}>
                                Actions
                            </HeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.isLoading && <NoResultsRow colSpan={9} text="Loading..." />}
                        {!users.isLoading && filteredData.length === 0 && (
                            <NoResultsRow colSpan={9} text="No records found" />
                        )}
                        {filteredData.map((user) => (
                            <TableRow key={user.id}>
                                <TableCell>{user.odEmpNum}</TableCell>
                                <TableCell>{user.username}</TableCell>
                                <TableCell>
                                    {user.firstName} {user.lastName}
                                </TableCell>
                                <TableCell align="center">{user.canPO ? 'Yes' : 'No'}</TableCell>
                                <TableCell align="center">{user.isAdmin ? 'Yes' : 'No'}</TableCell>
                                <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                                    <IconButton onClick={() => setUserId(user.id.toString())}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton
                                        onClick={async () => {
                                            const res = await confirmDialog(
                                                `Disable user ${user.username}`,
                                                'Are you sure you want to disable this user?',
                                            );
                                            if (!res) return;
                                            disableUser.mutate(user.id.toString());
                                        }}
                                    >
                                        <Icon>delete</Icon>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <UserPopup userId={userId} setUserId={setUserId} key={userId || 'none'} />
        </Grid2>
    );
}
