import { Close } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Grid2,
    IconButton,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import { Select } from 'components/Select/Select';
import { showSnackbar } from 'components/Snackbar/SnackbarProvider';
import { useUpdateOrderLine } from 'hooks/useUpdateOrderLine';
import React, { useEffect } from 'react';
import { OrderLineEditFields } from 'types/Orders';

export function EditOrderLinePopup({
    value,
    orderId,
    orderLineId,
    onClose,
    itemDescription,
}: {
    value: OrderLineEditFields;
    orderId: number;
    orderLineId: number;
    onClose: () => void;
    itemDescription: string;
}) {
    const updateOrderLine = useUpdateOrderLine();
    const [qty, setQty] = React.useState(value.qty);
    const [qtyType, setQtyType] = React.useState(value.qtyType || 'none');

    useEffect(() => {
        setQty(value.qty);
        setQtyType(value.qtyType || 'none');
    }, [value]);

    const update = () => {
        updateOrderLine.mutate(
            {
                orderId,
                orderLineId,
                newValues: { qty, qtyType: qtyType === 'none' ? null : qtyType },
            },
            {
                onSuccess: () => {
                    showSnackbar({ message: 'Item updated', severity: 'success' });
                    onClose();
                },
                onError: () => {
                    showSnackbar({ message: 'Failed to update item', severity: 'error' });
                },
            },
        );
    };
    return (
        <Dialog open maxWidth="xl" onClose={onClose}>
            <DialogTitle>
                Edit Item
                <IconButton style={{ position: 'absolute', top: 12, right: 12 }} onClick={onClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <Grid2 container spacing={2} style={{ padding: 16 }}>
                <Grid2 size={{ xs: 12 }}>
                    <Typography variant="body1">{itemDescription}</Typography>
                </Grid2>
                <Grid2 size={{ xs: 12 }}>
                    <Select
                        fullWidth
                        label="Mount Type"
                        size="small"
                        value={qtyType}
                        onChange={(e) => setQtyType(e.target.value)}
                    >
                        <MenuItem value="none" style={{ opacity: 0.5 }}></MenuItem>
                        <MenuItem value="LEFT">LEFT</MenuItem>
                        <MenuItem value="RIGHT">RIGHT</MenuItem>
                        <MenuItem value="UNIMOUNT">UNIMOUNT</MenuItem>
                    </Select>
                </Grid2>
                <Grid2 size={{ xs: 12 }}>
                    <TextField
                        label="Quantity"
                        type="number"
                        size="small"
                        fullWidth
                        value={qty}
                        onChange={(e) => setQty(parseInt(e.target.value))}
                    />
                </Grid2>
            </Grid2>
            <DialogActions>
                <Button onClick={update}>Update</Button>
            </DialogActions>
        </Dialog>
    );
}
