import config from 'global-variables';
import { useQuery } from 'react-query';
import { getToken } from './useLogin';
import { OpenOrder } from 'types/Orders';

async function getOpenOrders({ accountId }: { accountId?: string }): Promise<OpenOrder[]> {
    const token = getToken();
    if (!token) return [];
    const host = config.restAPIUrl;
    const res = await fetch(`${host}/orders/open${accountId && accountId !== 'none' ? `/${accountId}` : ''}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });
    const data = await res.json();
    return data as OpenOrder[];
}

export function useGetOpenOrders(accountId: string) {
    return useQuery(['open-orders', accountId], () => getOpenOrders({ accountId }), {
        refetchInterval: 0,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });
}
