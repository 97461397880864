import config from 'global-variables';
import { useMutation, useQueryClient } from 'react-query';
import { getToken } from './useLogin';
import { ScrapOutOrderRequest } from 'types/Orders';

async function createScrapOutOrder({ mcsnLocationId, odflLocationId }: ScrapOutOrderRequest) {
    const token = getToken();
    if (!token || !mcsnLocationId || !odflLocationId) return null;
    const host = config.restAPIUrl;

    const res = await fetch(`${host}/orders/provider/scrap-put`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ mcsnLocationId, odflLocationId }),
    });
    if (!res.ok) {
        const txtRes = await res.text();
        throw new Error('Error creating scrap out order: ' + txtRes);
    }
    const data = await res.json();
    return data;
}

export function useCreateScrapOutOrder() {
    const queryClient = useQueryClient();
    return useMutation((scrapOutOrder: ScrapOutOrderRequest) => createScrapOutOrder(scrapOutOrder), {
        onSuccess: (_, { mcsnLocationId }) => {
            queryClient.invalidateQueries(['provider-orders', mcsnLocationId.toString()]);
        },
    });
}
